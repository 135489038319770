.PorjectsSection{
    background-color: #1F2025;
    margin-top: 130px;
    border-top: dashed 3px #C2DF15;
}

.projectsTitles{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px 20px 20px;
}


.projectsTitles img{
    width: 30px;
    height: 30px;
}

.porjectsArea{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;
}

.ProjectsBox{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 350px;
    gap: 20px;
    padding: 10px;
    background-color: #41414d;
    border-radius: 15px;
    transition: all 0.3s ease;
}

.ProjectsBox:hover{
    transform: scale(1.02);
}

.ProjectsBox h3{
    color: white;
    padding: 0 0 0 10px;
}

 #porjectImg{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: solid 1.2px white;
    transition: all 0.3s ease;
}

#porjectImg:hover{
    transform: scale(1);
}

.ProjectsBox img{
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
}  

.ProjectsBox img:hover{
    transform: scale(0.9);
}

.technologies{
    display: flex;
    gap: 10px;
    padding: 0 0 0 10px;
}

.technologies img{
    width: 40px;
    height: 40px;
}


.projectLinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.projectLinks a{
    background-color: #C2DF15;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.projectLinks a:hover{
    transform: scale(0.9);
    background-color: #8da313;
}

.projectLinks img{
    width: 30px;
}

.allProjects{
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 10px
}

.allProjects a {
    text-decoration: none;
    color: white;
    background-color: #C2DF15;
    padding: 10px 20px;
    border-radius: 15px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    width: 130px;
    gap: 10px;
}

.allProjects a:hover{
    background-color: #8da313;
    transform: scale(0.9);
}

.allProjects img{
    width: 30px;
}

.AllProjects{
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 100px;
}


@media screen and (max-width: 500px){
    .porjectsArea{
        flex-direction: column;
        align-items: center;
    }
    .ProjectsBox{
        width: 80%;
    }
}