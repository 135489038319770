h1{
  color: #C2DF15;
}

h3{
  color:white;
}

strong{
  color: #C2DF15;
}

p{
    color: white;
    text-align: justify;
}

.aboutMe{
    width: 100%;
    padding: 30px;
    margin-top: 100px;
}