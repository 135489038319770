.resume{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0;
    margin-bottom: 100px;
    color: white;
    margin-top: 70px;
}

.resume aside{
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.resume button{
    background-color: #C2DF15;
    height: 40px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.resume a{
    text-decoration: none;
    color: white;
}

.resume button:hover{
    background-color: #41414d;
    transform: scale(0.9);
}

.resume button:active{
    transform: scale(0.8);
}


.resume img{
    width: 40%;
}

#back{
    height: 40px;
    cursor: pointer;
    transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-left: 50px;
}

#back:hover{
    transform: scale(0.85);
}

@media screen and (max-width: 768px) {
    .resume img{
        width: 90%;
    }

    .resume aside{
        width: 90%;
    }

    #back{
        margin-left: 10px;
    }
}