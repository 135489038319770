.contact {
    padding: 50px;
    padding-top: 100px;
    background-color: #2B2B35;
    font-family: Arial, sans-serif;
}

.contact h2 {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.contact form {
    max-width: 600px;
    margin: 0 auto;
    background: #1F2025;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.contact form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: white;
}

.contact form input,
.contact form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.contact form textarea {
    height: 150px;
    resize: vertical;
}

.contact form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
}

.contact form button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .contact {
        padding: 20px;
    }

    .contact form {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .contact form input,
    .contact form textarea {
        font-size: 14px;
        padding: 8px;
    }

    .contact form button {
        font-size: 16px;
        padding: 10px;
    }
}
