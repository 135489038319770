footer{
    background-color: #1F2025;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

footer span{
    color: gray;
}