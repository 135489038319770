@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  transition: all 0.15s;
  Scroll-behavior:smooth;
}

body {
  margin: 0;
  margin-top: 60px;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

  background-color: #2B2B35;
  overflow-x: hidden;
  overflow-y: scroll;
}


.websiteTop{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.websiteTop img{
  width: 350px;
  height: 350px;
  margin-right: 60px;
  margin-top: 50px;
}

.socialLinks{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
}

.socialLinks img:hover{
  transform: scale(1.1);
}

.socialLinks img{
  width: 50px;
  transition: 0.3s;
}

@media screen and (max-width: 750px){
  .websiteTop img{
    display: none;
  }
  .aboutMe{
    width: 100%;
    padding: 0;
  }
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: #3c3c3c #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #3c3c3c;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
